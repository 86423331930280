<template>
  <div class="custom-container">
    <div class="title">年度销售数据分析</div>
    <div class="custom-body">
      <div class="custom-left">
        <div class="table-container">
          <vxe-table :data="list">
            <vxe-table-column title="年度" field="name" min-width="100" show-overflow></vxe-table-column>
            <vxe-table-column title="含税销售额" field="actualSales" min-width="100" show-overflow></vxe-table-column>
            <vxe-table-column title="成本" field="actualCost" min-width="100" show-overflow></vxe-table-column>
            <vxe-table-column title="毛利" field="actualSold" min-width="100" show-overflow></vxe-table-column>
            <vxe-table-column title="费用" field="actualFee" min-width="100" show-overflow></vxe-table-column>
            <vxe-table-column title="利润" field="lastYearSales" min-width="100" show-overflow></vxe-table-column>
          </vxe-table>
        </div>
        <div class="bar-container">
          <EchartsBar title="年度品类销售数据" :echartsData="list" :seriesList="seriesStackList" :inverse="true"></EchartsBar>
        </div>
      </div>
      <div class="custom-right">
          <EchartsBar title="年度销售分析数据" :echartsData="list" :seriesList="seriesList" :inverse="true"></EchartsBar>
      </div>
    </div>

  </div>
</template>
<script>
import EchartsBar from '../components/echarts_bar.vue';

export default {
  components: {
    EchartsBar,
  },
  data() {
    return {
      list: [
        {
          name: '2019', actualSales: 19892632, actualSold: 3978526.4, actualCost: 9946316, actualFee: 5967789.6, lastYearSales: '18380791.97%', nc: 320, jg: 210, xq: 310, dc: 410,
        },
        {
          name: '2018', actualSales: 11867632, actualSold: 2373526.4, actualCost: 5933816, actualFee: 3560289.6, lastYearSales: '11499735.41%', nc: 330, jg: 230, xq: 330, dc: 330,
        },
        {
          name: '2017', actualSales: 16892234, actualSold: 3378446.8, actualCost: 8446117, actualFee: 5067670.2, lastYearSales: '16419251.45%', nc: 390, jg: 90, xq: 290, dc: 190,
        },
        {
          name: '2016', actualSales: 9856432, actualSold: 1971286.4, actualCost: 4928216, actualFee: 2956929.6, lastYearSales: '9324184.672%', nc: 334, jg: 134, xq: 234, dc: 154,
        },
        {
          name: '2015', actualSales: 8867841, actualSold: 1773568.2, actualCost: 4433920.5, actualFee: 2660352.3, lastYearSales: '8965387.251%', nc: 301, jg: 101, xq: 191, dc: 201,
        },
        {
          name: '2014', actualSales: 8696723, actualSold: 1739344.6, actualCost: 4348361.5, actualFee: 2609016.9, lastYearSales: '8331460.634%', nc: 302, jg: 132, xq: 182, dc: 212,
        },
        {
          name: '2013', actualSales: 19892632, actualSold: 3978526.4, actualCost: 9946316, actualFee: 5967789.6, lastYearSales: '18380791.97%', nc: 320, jg: 120, xq: 220, dc: 150,
        },
      ],
      seriesList: [
        {
          name: '销售额', type: 'bar', key: 'actualSales', label: { show: true, position: 'inside' },
        },
        {
          name: '成本', type: 'bar', key: 'actualCost', label: { show: true, position: 'inside' },
        },
        {
          name: '费用', type: 'bar', key: 'actualFee', label: { show: true, position: 'inside' },
        },
      ],
      seriesStackList: [
        {
          name: '内参', type: 'bar', stack: 'total', key: 'nc', label: { show: true, position: 'inside' },
        }, {
          name: '酒鬼', type: 'bar', stack: 'total', key: 'jg', label: { show: true, position: 'inside' },
        }, {
          name: '湘泉', type: 'bar', stack: 'total', key: 'xq', label: { show: true, position: 'inside' },
        }, {
          name: '洞藏', type: 'bar', stack: 'total', key: 'dc', label: { show: true, position: 'inside' },
        },
      ],
    };
  },
};
</script>
<style lang="less" scoped>
  ::-webkit-scrollbar {
    height: 0;
    width: 0;
    color: transparent;
  }
  .custom-container{
    width: 100%;
    height: 100%;
    overflow-y: scroll;
  }
  .title{
    font-size: 20px;
    text-align: center;
    color: #333333;
    font-weight: bold;
    padding: 10px 0;
  }
  .custom-body{
    width: 100%;
    display: flex;
    align-items: stretch;
  }
  .custom-left{
    width: 60%;
  }
  .custom-right{
    width: 40%;
  }
  .bar-container{
    width: 100%;
    height: 400px;
  }
</style>
