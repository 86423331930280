<template>
  <div id="bar" ref="bar"></div>
</template>
<script>
import echarts from 'echarts';

export default {
  name: 'echarts_bar',
  props: {
    echartsData: Array,
    title: String,
    inverse: {
      type: Boolean,
      default: false,
    },
    color: {
      type: Array,
      default() {
        return ['#57a1d5', '#86dde1', '#F7D871', '#e7a184'];
      },
    },
    seriesList: {
      type: Array,
      default() {
        return [
          { name: '销售额', type: 'bar', key: 'actualSales' },
          { name: '成本', type: 'bar', key: 'actualCost' },
        ];
      },
    },
  },
  data() {
    return {
      // 图表配置信息
      options: {},
    };
  },
  methods: {
    getOptions() {
      const dimensions = this.seriesList.map((v) => v.key);
      dimensions.unshift('name');
      const options = {
        title: {
          text: this.title,
          left: 30,
          top: 20,
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
        },
        color: this.color,
        legend: {
          top: 20,
          left: 'right',
        },
        grid: {
          top: 60,
          left: 30,
          right: 40,
          bottom: 20,
          containLabel: true,
        },
        xAxis: {
          type: 'value',
          boundaryGap: [0, 0.01],
        },
        yAxis: {
          type: 'category',
          inverse: this.inverse, // 是否为倒序
        },
        dataset: {
          dimensions,
          source: this.echartsData,
        },
        series: this.seriesList,
      };
      return options;
    },
    // 初始化echarts
    initEchart() {
      const chartDiv = this.$refs.bar;
      const myChart = echarts.init(chartDiv);
      myChart.setOption(this.getOptions());
    },
  },
  created() {

  },
  mounted() {
    this.$nextTick(() => {
      this.initEchart();
    });
  },
};
</script>
<style lang="less" scoped>
#bar{
  width: 100%;
  height: 100%;
}
</style>
