var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "custom-container" }, [
    _c("div", { staticClass: "title" }, [_vm._v("年度销售数据分析")]),
    _c("div", { staticClass: "custom-body" }, [
      _c("div", { staticClass: "custom-left" }, [
        _c(
          "div",
          { staticClass: "table-container" },
          [
            _c(
              "vxe-table",
              { attrs: { data: _vm.list } },
              [
                _c("vxe-table-column", {
                  attrs: {
                    title: "年度",
                    field: "name",
                    "min-width": "100",
                    "show-overflow": "",
                  },
                }),
                _c("vxe-table-column", {
                  attrs: {
                    title: "含税销售额",
                    field: "actualSales",
                    "min-width": "100",
                    "show-overflow": "",
                  },
                }),
                _c("vxe-table-column", {
                  attrs: {
                    title: "成本",
                    field: "actualCost",
                    "min-width": "100",
                    "show-overflow": "",
                  },
                }),
                _c("vxe-table-column", {
                  attrs: {
                    title: "毛利",
                    field: "actualSold",
                    "min-width": "100",
                    "show-overflow": "",
                  },
                }),
                _c("vxe-table-column", {
                  attrs: {
                    title: "费用",
                    field: "actualFee",
                    "min-width": "100",
                    "show-overflow": "",
                  },
                }),
                _c("vxe-table-column", {
                  attrs: {
                    title: "利润",
                    field: "lastYearSales",
                    "min-width": "100",
                    "show-overflow": "",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "bar-container" },
          [
            _c("EchartsBar", {
              attrs: {
                title: "年度品类销售数据",
                echartsData: _vm.list,
                seriesList: _vm.seriesStackList,
                inverse: true,
              },
            }),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "custom-right" },
        [
          _c("EchartsBar", {
            attrs: {
              title: "年度销售分析数据",
              echartsData: _vm.list,
              seriesList: _vm.seriesList,
              inverse: true,
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }